<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Page</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Page Component UI CMS</h5>
          <hr />

          <div class="form-body mt-4" v-if="dataLoaded">
            <div class="row mb-3">
              <div class="mt-3">
                <h6>Banner Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:" v-model="cmsData.bannerSectionTitleFontColor" :clearable="true"
                    :bottomBar="true" :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.bannerSectionSubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Font Color:"
                   v-model="cmsData.bannerSectionButtonFontColor"
                    :clearable="true" :bottomBar="true" :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Color:"
                    v-model="cmsData.bannerSectionButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Hover Color:"
                    v-model="cmsData.bannerSectionButtonHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
                <h6>Product Category Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                   v-model="cmsData.productCategoryTitleFontColor"
                    :clearable="true" :bottomBar="true" :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.productCategorySubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Hover Color:"
                    v-model="cmsData.productCategoryHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Font Color:"
                    v-model="cmsData.productCategoryButtonFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Color:"
                    v-model="cmsData.productCategoryButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Hover Color:"
                    v-model="cmsData.productCategoryButtonHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              
              <div class="mt-5">
                <h6>Explore Work Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                    v-model="cmsData.exploreWorkTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Colorr:"
                    v-model="cmsData.exploreWorkButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Hover Colorr:"
                    v-model="cmsData.exploreWorkButtonHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Font Color:"
                    v-model="cmsData. exploreWorkButtonFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
                <h6>New Arrival Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                    v-model="cmsData.newArrivalTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.newArrivalSubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Heading Font Color:"
                    v-model="cmsData.newArrivalHeadingColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Color:"
                    v-model="cmsData.newArrivalButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
                <h6>Retailer Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                    v-model="cmsData.retailerSectionTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.retailerSectionSubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Heading Font Color:"
                    v-model="cmsData.retailerSectionHeadingColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
                <h6>News Letter Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                    v-model="cmsData.newsLetterTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.newsLetterSubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Heading Font Color:"
                    v-model="cmsData.newsLetterHeadingColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Font Color:"
                    v-model="cmsData.newsLetterButtonFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Color:"
                    v-model="cmsData.newsLetterButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Hover Color:"
                    v-model="cmsData.newsLetterButtonHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
                <h6>Blog Section</h6>
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Title Font Color:"
                    v-model="cmsData.blogSectionTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Sub Title Font Color:"
                    v-model="cmsData.blogSectionSubTitleFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Heading Font Color:"
                    v-model="cmsData.blogSectionHeadingColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Font Color:"
                    v-model="cmsData.blogSectionButtonFontColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Background Color:"
                    v-model="cmsData.blogSectionButtonBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Button Hover Color:"
                    v-model="cmsData.blogSectionButtonHoverColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
              <div class="mt-5">
              </div>
              <hr />
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker label="Page Background Color:"
                    v-model="cmsData.pageBackgroundColor" :clearable="true" :bottomBar="true"
                    :colors="colors" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="d-grid">
                <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                  <button class="btn btn-primary" @click="update" type="button">Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/home-page-component-ui.js"></script>