import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: this.$serverURL + this.$api.product.list,

            name: "",
            description: "",
            featuredImage: "",
            previewFeaturedImage: "",
            isFeatured: false,

            category: {},
            categoryList: [],
        }

    },
    async mounted() {
        await this.getCategoryList();
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput;
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getCategoryList: async function () {
            let url = this.$serverURL + this.$api.category.categoryAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product description!"
                });
                return false;
            }
            if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            }
            if (!(this.categoryList?.find((cat)=> cat.id == this.category?.id))) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            }
            return true;
        },
        addProduct: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("name", this.name);
                    formData.append("description", this.description);
                    formData.append("featuredImage", this.featuredImage);
                    formData.append("categoryId", this.category.id);
                    formData.append("categoryTitle", this.category.title);
                    formData.append("isFeatured", this.isFeatured ? 1 : 0);

                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/product-list");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    },
}