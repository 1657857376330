import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.retailers.requestURL,
            id: this.$route.params.id,
            previewImage: "",
            singleRetailer: {},
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            counter: 0,
        }

    },
    async mounted() {
        await this.getSingleRetailer();
    },
    methods: {
        uploadBlogPhoto: function (event) {
            this.singleBlog.image = event.target.files[0];
            let input = this.$refs.image;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.singleRetailer.retailerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add retailer name."
                })
                return false;
            } else if (!this.singleRetailer.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add address."
                })
                return false;
            } else if (!this.singleRetailer.retailerLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add retailer link."
                })
                return false;
            }
            return true;
        },
        getSingleRetailer: async function () {
            let config = {
                method: "GET",
                url: this.setURL + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleRetailer = await response.data.data[0];
                    this.singleRetailer.isActive = this.singleRetailer.isActive ? true : false;
                    let index = 0;
                    for (const [key, value] of Object.entries(JSON.parse(this.singleRetailer.services))) {
                        this.specificationKey[index] = key;
                        this.specificationValue[index] = value;
                        this.specificationArray[this.specificationKey[index]] = this.specificationValue[index];
                        index++;
                        this.counter = this.counter + 1;
                    }
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter a service!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        finalSpecification() {
            let index = this.counter - 1;
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service!"
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationValue[index]];
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        setAddress(locationData) {
            this.singleRetailer.address = locationData.formatted_address;
            this.singleRetailer.latitude = locationData.geometry.location.lat();
            this.singleRetailer.longitude = locationData.geometry.location.lng()
        },
        updateRetailer: async function () {
            if (this.validate()) {
                try {
                    this.specificationArray = {};
                    for (let i = 0; i < this.counter; i++) {
                        this.specificationArray[i] = this.specificationValue[i];
                    }

                    if (!this.finalSpecification()) {
                        return;
                    }

                    let data = {
                        retailerName: this.singleRetailer.retailerName,
                        address: this.singleRetailer.address,
                        latitude: this.singleRetailer.latitude,
                        longitude: this.singleRetailer.longitude,
                        retailerLink: this.singleRetailer.retailerLink,
                        isActive: this.singleRetailer.isActive ? 1 : 0,
                        services: JSON.stringify(this.specificationArray)
                    };
                    let config = {
                        method: 'PATCH',
                        url: this.setURL + this.id,
                        data: data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.updateDataToBackend(config);
                    this.$router.push("/retailers");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        }
    },
}