import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            url: this.$serverURL + this.$api.componentUI.homePage,
            cmsData: {},
            dataLoaded: false,
            colors: [
                "#E91E63",
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548" 
            ]
        }
    },
    async created() {
        document.title = "Mac Pharms - About Page";
        await this.getData();
        this.dataLoaded = true;
    },
    methods: {
        async getData() {
            let response = await this.getCmsData(this.url);
            if (response.data.statusCode == 200) {
                this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
            }
            else {
                this.cmsData = {};
            }
        },
        async update() {
            try {
                let data = {
                    bannerSectionTitleFontColor: this.cmsData.bannerSectionTitleFontColor ? this.cmsData.bannerSectionTitleFontColor : "#146C43",
                    bannerSectionSubTitleFontColor: this.cmsData.bannerSectionSubTitleFontColor ? this.cmsData.bannerSectionSubTitleFontColor : "#6C757D",
                    bannerSectionButtonFontColor: this.cmsData.bannerSectionButtonFontColor ? this.cmsData.bannerSectionButtonFontColor : "#6C757D",
                    bannerSectionButtonBackgroundColor: this.cmsData.bannerSectionButtonBackgroundColor ? this.cmsData.bannerSectionButtonBackgroundColor : "#298F60",
                    bannerSectionButtonHoverColor: this.cmsData.bannerSectionButtonHoverColor ? this.cmsData.bannerSectionButtonHoverColor : "#146C43",
                    productCategoryTitleFontColor: this.cmsData.productCategoryTitleFontColor ? this.cmsData.productCategoryTitleFontColor : "#212529",
                    productCategorySubTitleFontColor: this.cmsData.productCategorySubTitleFontColor ? this.cmsData.productCategorySubTitleFontColor : "#6C757D",
                    productCategoryHoverColor: this.cmsData.productCategoryHoverColor ? this.cmsData.productCategoryHoverColor : "#196F47",
                    productCategoryButtonFontColor: this.cmsData.productCategoryButtonFontColor ? this.cmsData.productCategoryButtonFontColor : "#198754",
                    productCategoryButtonBackgroundColor: this.cmsData.productCategoryButtonBackgroundColor ? this.cmsData.productCategoryButtonBackgroundColor : "#298F60",
                    productCategoryButtonHoverColor: this.cmsData.productCategoryButtonHoverColor ? this.cmsData.productCategoryButtonHoverColor : "#146C43",
                    exploreWorkTitleFontColor: this.cmsData.exploreWorkTitleFontColor ? this.cmsData.exploreWorkTitleFontColor : "#212529",
                    exploreWorkButtonBackgroundColor: this.cmsData.exploreWorkButtonBackgroundColor ? this.cmsData.exploreWorkButtonBackgroundColor : "#298F60",
                    exploreWorkButtonHoverColor: this.cmsData.exploreWorkButtonHoverColor ? this.cmsData.exploreWorkButtonHoverColor : "#146C43",
                    exploreWorkButtonFontColor: this.cmsData.exploreWorkButtonFontColor ? this.cmsData.exploreWorkButtonFontColor : "#198754",
                    newArrivalTitleFontColor: this.cmsData.newArrivalTitleFontColor ? this.cmsData.newArrivalTitleFontColor : "#212529",
                    newArrivalSubTitleFontColor: this.cmsData.newsLetterSubTitleFontColor ? this.cmsData.newArrivalSubTitleFontColor : "#6C757D",
                    newArrivalHeadingColor: this.cmsData.newArrivalHeadingColor ? this.cmsData.newArrivalHeadingColor : "#298F60",
                    newArrivalButtonBackgroundColor: this.cmsData.newArrivalButtonBackgroundColor ? this.cmsData.newArrivalButtonBackgroundColor : "#298F60",
                    retailerSectionTitleFontColor: this.cmsData.retailerSectionTitleFontColor ? this.cmsData.retailerSectionTitleFontColor : "#212529",
                    retailerSectionSubTitleFontColor: this.cmsData.retailerSectionSubTitleFontColor ? this.cmsData.retailerSectionSubTitleFontColor : "#6C757D",
                    retailerSectionHeadingColor: this.cmsData.retailerSectionHeadingColor ? this.cmsData.retailerSectionHeadingColor : "#298F60",
                    newsLetterTitleFontColor: this.cmsData.newsLetterTitleFontColor ? this.cmsData.newsLetterTitleFontColor : "#F8F9FA",
                    newsLetterSubTitleFontColor: this.cmsData.newsLetterSubTitleFontColor ? this.cmsData.newsLetterSubTitleFontColor : "#F8F9FA",
                    newsLetterHeadingColor: this.cmsData.newsLetterHeadingColor ? this.cmsData.newsLetterHeadingColor : "#F8F9FA",
                    newsLetterButtonFontColor: this.cmsData.newsLetterButtonFontColor ? this.cmsData.newsLetterButtonFontColor : "#198754",
                    newsLetterButtonBackgroundColor: this.cmsData.newsLetterButtonBackgroundColor ? this.cmsData.newsLetterButtonBackgroundColor : "#298F60",
                    newsLetterButtonHoverColor: this.cmsData.newsLetterButtonHoverColor ? this.cmsData.newsLetterButtonHoverColor : "#146C43",
                    blogSectionTitleFontColor: this.cmsData.blogSectionTitleFontColor ? this.cmsData.blogSectionTitleFontColor : "#212529",
                    blogSectionSubTitleFontColor: this.cmsData.blogSectionSubTitleFontColor ? this.cmsData.blogSectionSubTitleFontColor : "#6C757D",
                    blogSectionHeadingColor: this.cmsData.blogSectionHeadingColor ? this.cmsData.blogSectionHeadingColor : "#298F60",
                    blogSectionButtonFontColor: this.cmsData.blogSectionButtonFontColor ? this.cmsData.blogSectionButtonFontColor : "#198754",
                    blogSectionButtonBackgroundColor: this.cmsData.blogSectionButtonBackgroundColor ? this.cmsData.blogSectionButtonBackgroundColor : "#298F60",
                    blogSectionButtonHoverColor: this.cmsData.blogSectionButtonHoverColor ? this.cmsData.blogSectionButtonHoverColor : "#146C43",
                    pageBackgroundColor: this.cmsData.pageBackgroundColor ? this.cmsData.pageBackgroundColor : "#FFFFFF",
                }
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}