export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.home.productsSectionCMS,
            cmsData: {
                title: "",
                subTitle:"",
                buttonText: "",
                buttonLink: "",
            }
        }
    },
    async created() {
        document.title = "Mac Pharms - Home Page Products Section CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                // this.getCoordinates();
                let data = {
                    title: this.cmsData?.title,
                    subTitle: this.cmsData?.subTitle,
                    buttonText: this.cmsData?.buttonText,
                    buttonLink: this.cmsData?.buttonLink
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        
    }
}