import { authHeader } from "../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.retailers.requestURL,
            specificationValue: [],
            counter: 1,
            retailerName: "",
            address: "",
            retailerLink: "",
            isActive: false,
        }

    },
    async mounted() {
    },
    methods: {
        validate() {
            if (!this.retailerName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add retailer name."
                })
                return false;
            } else if (!this.address) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add address."
                })
                return false;
            } else if (!this.retailerLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please add retailer link."
                })
                return false;
            }
            return true;
        },
        addSpecification() {
            let index = this.counter - 1;
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter a service!"
                });
                return;
            }
            this.counter = this.counter + 1;
        },
        finalSpecification() {
            let index = this.counter - 1;
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter service!"
                });
                return false;
            }
            return true;
        },
        deleteSpecificationRow(index) {
            delete this.specificationArray[this.specificationValue[index]];
            this.specificationValue.splice(index, 1);
            this.counter = this.counter - 1;
        },
        addRetailer: async function () {
            this.specificationArray = {};
            for (let i = 0; i < this.counter; i++) {
              this.specificationArray[i] = this.specificationValue[i];
            }
      
            if (!this.finalSpecification()) {
              return;
            }
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let data = {
                        retailerName: this.retailerName,
                        address: this.address,
                        latitude: this.latitude,
                        longitude: this.longitude,
                        retailerLink: this.retailerLink,
                        isActive: this.isActive ? 1 : 0,
                        services: JSON.stringify(this.specificationArray)
                    };
                    let url = this.$serverURL + this.$api.retailers.requestURL;
                    let config = {
                        method: 'POST',
                        url: url,
                        data: data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/retailers");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
        setAddress(locationData) {
            this.address = locationData.formatted_address;
            this.latitude = locationData.geometry.location.lat();
            this.longitude = locationData.geometry.location.lng()
        },
    }
}