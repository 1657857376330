<template>
    <div class="sidebar-wrapper" data-simplebar="true">
        <div class="sidebar-header">
            <div>
                <img src="../../../../public/core/assets/logo.png" style="width: 60px;" class="logo-icon" alt="logo icon" />
            </div>
            <div>
                <h4 class="logo-text" style="font-size: 18px;">Mac Pharms</h4>
            </div>
            <div class="toggle-icon ms-auto">
                <i class="bx bx-arrow-to-left"></i>
            </div>
        </div>
        <ul class="metismenu" id="menu">
            <li>
                <a href="javascript:void(0)" @click="$router.push('/home')">
                    <div class="parent-icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <div class="menu-title">Dashboard</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/contact')">
                    <div class="parent-icon">
                        <i class='bx bxs-contact'></i>
                    </div>
                    <div class="menu-title">Contact</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/category')">
                    <div class="parent-icon">
                        <i class='bx bx-category-alt'></i>
                    </div>
                    <div class="menu-title">Category</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/product-list')">
                    <div class="parent-icon">
                        <i class='bx bxs-package'></i>
                    </div>
                    <div class="menu-title">Product</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/retailers')">
                    <div class="parent-icon">
                        <i class='bx bxs-store'></i>
                    </div>
                    <div class="menu-title">Retailers</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/blog-post')">
                    <div class="parent-icon">
                        <i class="fa-solid fa-blog"></i>
                    </div>
                    <div class="menu-title">Blogs</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/events')">
                    <div class="parent-icon">
                        <i class='bx bx-calendar-event'></i>
                    </div>
                    <div class="menu-title">Events</div>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" @click="$router.push('/newsletter')">
                    <div class="parent-icon">
                        <i class='bx bxs-news'></i>
                    </div>
                    <div class="menu-title">Newsletter</div>
                </a>
            </li>
            <li>
                <a href="#" class="has-arrow">
                    <div class="parent-icon">
                        <i class="fadeIn animated bx bxs-briefcase-alt-2"></i>
                    </div>
                    <div class="menu-title">Career</div>
                </a>
                <ul>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/career')">
                            <i class="bx bx-right-arrow-alt"></i>Career List
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/career-applicant')">
                            <i class="bx bx-right-arrow-alt"></i>Career Applicant
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-cog'></i>
                    </div>
                    <div class="menu-title">CMS Settings</div>
                </a>
                <ul>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class="fa-solid fa-home"></i>
                            </div>
                            Home Page
                        </a>
                        <ul>
                            <li>
                                <a href="/sliders" @click.prevent="$router.push('/sliders')">
                                    <i class="bx bx-right-arrow-alt"></i>Hero Section
                                </a>
                            </li>
                            <li>
                                <a href="/products-section-cms" @click.prevent="$router.push('/products-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Products Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/new-arrival-section-cms" @click.prevent="$router.push('/new-arrival-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>New Arrival Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/retailers-section-cms" @click.prevent="$router.push('/retailers-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Retailers Section CMS
                                </a>
                            </li>
                            <li>
                                <a href="/article-section-cms" @click.prevent="$router.push('/article-section-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Article Section CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-error-circle'></i>
                            </div>
                            About Us
                        </a>
                        <ul>
                            <li>
                                <a href="/about-us-section-one-cms" @click.prevent="$router.push('/about-us-section-one-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section One CMS
                                </a>
                            </li>
                            <li>
                                <a href="/about-us-section-two-cms" @click.prevent="$router.push('/about-us-section-two-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Two CMS
                                </a>
                            </li>
                            <li>
                                <a href="/about-us-section-three-cms" @click.prevent="$router.push('/about-us-section-three-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Section Three CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a class="has-arrow" href="javascript:void(0)">
                            <div class="parent-icon">
                                <i class='bx bx-briefcase-alt'></i>
                            </div>
                            Career
                        </a>
                        <ul>
                            <!-- <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/career-list-page-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Career Page CMS
                                </a>
                            </li> -->
                            <li>
                                <a href="/footer-cms" @click.prevent="$router.push('/apply-career-modal-cms')">
                                    <i class="bx bx-right-arrow-alt"></i>Career Modal CMS
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/social-links')">
                            <i class="bx bx-right-arrow-alt"></i>Social Links
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/age-verification-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Age Verification CMS
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/adventure-section-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Adventure Section CMS
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/header-footer-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Header Footer CMS
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" @click="$router.push('/newsletter-banner-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Newsletter Banner CMS
                        </a>
                    </li>
                    <li>
                        <a href="/contact-page-cms" @click.prevent="$router.push('/contact-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-policy-page-cms" @click.prevent="$router.push('/privacy-policy-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Privacy Policy Page CMS
                        </a>
                    </li>
                    <li>
                        <a href="/terms-and-condition-page-cms"
                            @click.prevent="$router.push('/terms-and-condition-page-cms')">
                            <i class="bx bx-right-arrow-alt"></i>Terms & Condition Page CMS
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3m11 0h-4V8h4m-3 4h2m4-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/></svg>
                    <div class="menu-title">SEO</div>
                </a>
                <ul>
                    <li>

                    <a href="javascript:void(0);" @click="$router.push('/page-title-cms')">
                      <i class="bx bx-right-arrow-alt"></i>Page Title CMS
                    </a>
                    </li>
                    <li>
                    <a href="/product-meta" @click.prevent="$router.push('/product-meta')">
                    <i class="bx bx-right-arrow-alt"></i>Product Meta
                    </a>
                </li>
     
                </ul>
            </li>
            <li>
                <a class="has-arrow" href="javascript:void(0)">
                    <div class="parent-icon">
                        <i class='bx bxs-component'></i>
                    </div>
                    <div class="menu-title">Component UI</div>
                </a>
                <ul>
                    <li>
                        <a href="/home-page-component-ui"
                            @click.prevent="$router.push('/home-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Home Page
                        </a>
                    </li>
                    <li>
                        <a href="/header-footer-component-ui"
                            @click.prevent="$router.push('/header-footer-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Header Footer
                        </a>
                    </li>
                    <li>
                        <a href="/product-page-component-ui"
                            @click.prevent="$router.push('/product-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Product Page
                        </a>
                    </li>
                    <li>
                        <a href="/product-details-page-component-ui"
                            @click.prevent="$router.push('/product-details-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Product Details Page
                        </a>
                    </li>
                    <li>
                        <a href="/about-us-component-ui"
                            @click.prevent="$router.push('/about-us-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>About Us
                        </a>
                    </li>
                    <li>
                        <a href="/career-page-component-ui"
                            @click.prevent="$router.push('/career-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Career Page
                        </a>
                    </li>
                    <li>
                        <a href="/career-details-page-component-ui"
                            @click.prevent="$router.push('/career-details-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Career Details Page
                        </a>
                    </li>
                    <li>
                        <a href="/contact-page-component-ui"
                            @click.prevent="$router.push('/contact-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Contact Page
                        </a>
                    </li>
                    <li>
                        <a href="/blog-page-component-ui"
                            @click.prevent="$router.push('/blog-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Blog Page
                        </a>
                    </li>
                    <li>
                        <a href="/event-page-component-ui"
                            @click.prevent="$router.push('/event-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Event Page
                        </a>
                    </li>
                    <li>
                        <a href="/featured-page-component-ui"
                            @click.prevent="$router.push('/featured-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Featured Page
                        </a>
                    </li>
                    <li>
                        <a href="/retailer-page-component-ui"
                            @click.prevent="$router.push('/retailer-page-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Retailer Page
                        </a>
                    </li>
                    <li>
                        <a href="/privacy-policy-component-ui"
                            @click.prevent="$router.push('/privacy-policy-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a href="/terms-and-conditions-component-ui"
                            @click.prevent="$router.push('/terms-and-conditions-component-ui')">
                            <i class="bx bx-right-arrow-alt"></i>Terms and Conditions
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>