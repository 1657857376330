import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: this.$serverURL + this.$api.event.list,

            title: "",
            description: "",
            image: "",
            previewImage: "",
        }

    },
    async mounted() {
        await this.getCategoryList();
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.image = event.target.files[0];
            let input = this.$refs.imageInput;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        checkValidation: function () {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter event title!"
                });
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter event description!"
                });
                return false;
            }
            if (!this.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter image!"
                });
                return false;
            }
            return true;
        },
        addEvent: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("title", this.title);
                    formData.append("description", this.description);
                    formData.append("image", this.image);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/events");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        }
    },
}