// import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.cms.pageTitleCMS,
            pageCMSData: {
                homePageTitle: "",
                productPagetitle: "",
                featuredPageTitle: "",
                blogPageTitle: "",
                retailerPageTitle: "",
                aboutUsPageTitle: "",
                joinOurTeamPageTitle: "",
                careerPageTitle: "",
                contactPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                homePageMetaTitle : "",
                productPageMetaTitle : "",
                featuredPageMetaTitle : "",
                blogPageMetaTitle : "",
                retailerPageMetaTitle : "",
                eventPageMetaTitle : "",
                aboutUsPageMetaTitle : "",
                joinOurTeamPageMetaTitle : "",
                careerPageMetaTitle : "",
                contactPageMetaTitle : "",
                privacyPageMetaTitle : "",
                termsPageMetaTitle : "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                productPageMetaKeyword: "",
                productPageMetaDescription: "",
                featuredPageMetaKeyword: "",
                featuredPageMetaDescription: "",
                blogPageMetaKeyword: "",
                blogPageMetaDescription: "",
                retailerPageMetaKeyword: "",
                retailerPageMetaDescription: "",
                aboutUsPageMetaKeyword: "",
                aboutUsPageMetaDescription: "",
                joinOurTeamPageMetaKeyword: "",
                joinOurTeamPageMetaDescription: "",
                careerPageMetaKeyword: "",
                careerPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
            }
        }
    },
    async mounted() {
        document.title = "MacPharms - Page Title";
       const datas =  await this.getCmsData(this.setURL);
        if (datas?.data?.data?.length) {
            this.pageCMSData = datas?.data?.data[0];
        } else {
            this.pageCMSData = {
                homePageTitle: "",
                productPagetitle: "",
                featuredPageTitle: "",
                blogPageTitle: "",
                retailerPageTitle: "",
                eventPageTitle: "",
                aboutUsPageTitle: "",
                joinOurTeamPageTitle: "",
                careerPageTitle: "",
                contactPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                returnPageTitle: "",
                homePageMetaTitle : "",
                productPageMetaTitle : "",
                featuredPageMetaTitle : "",
                blogPageMetaTitle : "",
                retailerPageMetaTitle : "",
                eventPageMetaTitle : "",
                aboutUsPageMetaTitle : "",
                joinOurTeamPageMetaTitle : "",
                careerPageMetaTitle : "",
                contactPageMetaTitle : "",
                privacyPageMetaTitle : "",
                termsPageMetaTitle : "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                productPageMetaKeyword: "",
                productPageMetaDescription: "",
                featuredPageMetaKeyword: "",
                featuredPageMetaDescription: "",
                blogPageMetaKeyword: "",
                blogPageMetaDescription: "",
                retailerPageMetaKeyword: "",
                retailerPageMetaDescription: "",
                eventPageMetaKeyword: "",
                eventPageMetaDescription: "",
                aboutUsPageMetaKeyword: "",
                aboutUsPageMetaDescription: "",
                joinOurTeamPageMetaKeyword: "",
                joinOurTeamPageMetaDescription: "",
                careerPageMetaKeyword: "",
                careerPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
            };
        }
    },
    methods: {
        async update() {
            try {
                let data = {
                    homePageTitle: this.pageCMSData.homePageTitle,
                    productPagetitle: this.pageCMSData.productPagetitle,
                    featuredPageTitle: this.pageCMSData.featuredPageTitle,
                    blogPageTitle: this.pageCMSData.blogPageTitle,
                    retailerPageTitle: this.pageCMSData.retailerPageTitle,
                    eventPageTitle: this.pageCMSData.eventPageTitle,
                    aboutUsPageTitle: this.pageCMSData.aboutUsPageTitle,
                    joinOurTeamPageTitle: this.pageCMSData.joinOurTeamPageTitle,
                    careerPageTitle: this.pageCMSData.careerPageTitle,
                    contactPageTitle: this.pageCMSData.contactPageTitle,
                    privacyPageTitle: this.pageCMSData.privacyPageTitle,
                    termsPageTitle: this.pageCMSData.termsPageTitle,
                    homePageMetaTitle : this.pageCMSData.homePageMetaTitle,
                    productPageMetaTitle : this.pageCMSData.productPageMetaTitle,
                    featuredPageMetaTitle : this.pageCMSData.featuredPageMetaTitle,
                    blogPageMetaTitle : this.pageCMSData.blogPageMetaTitle,
                    retailerPageMetaTitle : this.pageCMSData.retailerPageMetaTitle,
                    eventPageMetaTitle : this.pageCMSData.eventPageMetaTitle,
                    aboutUsPageMetaTitle : this.pageCMSData.aboutUsPageMetaTitle,
                    joinOurTeamPageMetaTitle : this.pageCMSData.joinOurTeamPageMetaTitle,
                    careerPageMetaTitle : this.pageCMSData.careerPageMetaTitle,
                    contactPageMetaTitle : this.pageCMSData.contactPageMetaTitle,
                    privacyPageMetaTitle : this.pageCMSData.privacyPageMetaTitle,
                    termsPageMetaTitle : this.pageCMSData.termsPageMetaTitle,
                    homePageMetaKeyword: this.pageCMSData.homePageMetaKeyword,
                    homePageMetaDescription: this.pageCMSData.homePageMetaDescription,
                    productPageMetaKeyword: this.pageCMSData.productPageMetaKeyword,
                    productPageMetaDescription: this.pageCMSData.productPageMetaDescription,
                    featuredPageMetaKeyword: this.pageCMSData.featuredPageMetaKeyword,
                    featuredPageMetaDescription: this.pageCMSData.featuredPageMetaDescription,
                    blogPageMetaKeyword: this.pageCMSData.blogPageMetaKeyword,
                    blogPageMetaDescription: this.pageCMSData.blogPageMetaDescription,
                    retailerPageMetaKeyword: this.pageCMSData.retailerPageMetaKeyword,
                    retailerPageMetaDescription: this.pageCMSData.retailerPageMetaDescription,
                    eventPageMetaKeyword: this.pageCMSData.eventPageMetaKeyword,
                    eventPageMetaDescription: this.pageCMSData.eventPageMetaDescription,
                    aboutUsPageMetaKeyword: this.pageCMSData.aboutUsPageMetaKeyword,
                    aboutUsPageMetaDescription: this.pageCMSData.aboutUsPageMetaDescription,
                    joinOurTeamPageMetaKeyword: this.pageCMSData.joinOurTeamPageMetaKeyword,
                    joinOurTeamPageMetaDescription: this.pageCMSData.joinOurTeamPageMetaDescription,
                    careerPageMetaKeyword: this.pageCMSData.careerPageMetaKeyword,
                    careerPageMetaDescription: this.pageCMSData.careerPageMetaDescription,
                    contactPageMetaKeyword: this.pageCMSData.contactPageMetaKeyword,
                    contactPageMetaDescription: this.pageCMSData.contactPageMetaDescription,
                    privacyPageMetaKeyword: this.pageCMSData.privacyPageMetaKeyword,
                    privacyPageMetaDescription: this.pageCMSData.privacyPageMetaDescription,
                    termsPageMetaKeyword: this.pageCMSData.termsPageMetaKeyword,
                    termsPageMetaDescription: this.pageCMSData.termsPageMetaDescription,
                }
                // let config = {
                //     method: "POST",
                //     url: this.$serverURL + this.$api.cms.pageTitleCMS,
                //     data: data,
                //     headers: {
                //         "Authorization": authHeader()
                //     }
                // };
                console.log(this.pageCMSData.joinOurTeamPageMetaTitle);
                await this.createUpdateCMS(this.setURL, data);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}