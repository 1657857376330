// import { authHeader } from '../../../auth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "AddCareer",
    data() {
        return {
            title: '',
            jobType: '',
            description: '',
            salary: '',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
        }
    },
    async created() {
        document.title = "Mac Pharms - Add Career";
    },
    methods: {
        validate: function() {
            if(!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title!"
                });
                return false;
            }
            if(!this.jobType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Job Type!"
                });
                return false;
            }
            if(!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter description!"
                });
                return false;
            }
            if(!this.salary) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter salary!"
                });
                return false;
            }
            return true;
        },
        save: async function() {
            if(this.validate()) {
                let token = localStorage.getItem('token');
                let formData = {
                    title: this.title,
                    jobType: this.jobType,
                    description: this.description,
                    salary: this.salary
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.career.list,
                    data: formData,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                await this.$axios(config).then((response) => {
                    if(response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                    this.title = '';
                    this.jobType = '';
                    this.description = '';
                    this.salary = '';
                    this.$router.push("/career");
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        }
    }
}