<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update Product
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Update Product</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Product Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="name"
                        placeholder="Enter product name" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <label class="form-label" for="description">Description:</label>
                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Featured Image:
                        <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                        ref="featuredImageInput" aria-describedby="imageHelp" accept="image/*"
                        @change="uploadFeaturedImage" />
                      <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="90" />
                      <img v-else :src="featuredImage" height="80" />
                    </div>
                  </div>


                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="categoryList" class="form-label">Category:</label>
                      <select v-model="category" class="form-select form-control mb-3" id="categoryList">
                        <option class="p-1" v-for="(row, index) in categoryList" :key="index"
                          :value="{ id: row.id, title: row.title }">
                          {{ row.title }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-3">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Is Featured: </label>
                      <div class="form-check form-switch mt-1">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                          v-model="isFeatured" />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="updateProduct" class="btn btn-primary">
                        Save Product
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/update-product.js'></script>