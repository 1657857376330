import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.category.categoryAPI,
            singleAPI: this.$serverURL + this.$api.category.singleCategoryAPI,

            description: "",
            name: "",
            icon: "",
            previewIcon: "",
        }

    },
    async mounted() {
    },
    methods: {
        uploadcategoryIcon: function (event) {
            this.icon = event.target.files[0];
            let input = this.$refs.icon;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewIcon = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        validate() {
            if (!this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add category title."
                })
                return false;
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add category description."
                })
                return false;
            }
            if (!this.icon) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add category icon."
                })
                return false;
            }
            return true;
        },
        addcategory: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append('title', this.title)
                    formData.append('description', this.description)
                    formData.append('icon', this.icon);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.postDataToBackend(config);
                    this.$router.push("/category");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
    }
}