import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.event.list,
            singleEvent: {},
            title: "",
            description: "",
            image: "",
            previewImage: "",
        }

    },
    async mounted() {
        await this.getEventDetails();
    },
    methods: {
        getEventDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data?.length) {
                            this.singleEvent = response.data.data[0];
                            if (this.singleEvent.title) {
                                this.title = this.singleEvent.title;
                            }
                            if (this.singleEvent.description) {
                                this.description = this.singleEvent.description;
                            }
                            if (this.singleEvent.image) {
                                this.image = this.singleEvent.image;
                                this.previewImage = this.image;
                            }
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage(event) {
            this.singleEvent.image = event.target.files[0];
            let input = this.$refs.featuredInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        checkValidation: function () {
            if (!this.singleEvent.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter event title!"
                });
                return false;
            }
            if (!this.singleEvent.description) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter event description!"
                });
                return false;
            }
            if (!this.singleEvent.image) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter image!"
                });
                return false;
            }
            return true;
        },
        updateEvent: async function () {
            if (this.checkValidation()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append("title", this.singleEvent.title);
                    formData.append("description", this.singleEvent.description);
                    formData.append("image", this.singleEvent.image);
                    let config = {
                        method: 'PATCH',
                        url: this.siteUrl + '/' + this.id,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.updateDataToBackend(config);
                    this.$swal.hideLoading();
                    this.$router.push("/events");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }
        },
    }
}