import { createApp } from 'vue';
import App from './App.vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './routes/routes';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import mixins from './mixin';
import 'vue-select/dist/vue-select.css';
import vSelect from "vue-select";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
const app = createApp(App);
app.config.globalProperties.$api = require('../config/api.json');
const server = require('../config/server.json');
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.component("v-select", vSelect);
app.component('VueDatePicker', VueDatePicker);
app.use(CKEditor);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDCXiBEohsiIq47at86HyjVLIBnc1qTxrA',
        // language: 'de',
        libraries: "places"
    },
});
app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('MM/DD/YYYY hh:mm:ss A');
    },
    formatTime(date) {
        return moment(String(date)).format('hh:mm:ss A');
    },
    formatCouponDate(date) {
        return moment(String(date)).format('MM/DD/YYYY');
    },
    formatUpdateDate(date) {
        return moment(String(date)).format('MMMM DD YYYY');
    },
    dateFormat(date) {
        return moment(String(date)).format('MM/DD/YYYY');
    },
    formatDateTimeOrder(date) {
        return moment(String(date)).format('MM/DD/YYYY hh:mm:ss A');
    }
};

app.config.globalProperties.$axios = axios;

if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {

    app.config.globalProperties.$hostURL = server.STAGING_HOST;
    app.config.globalProperties.$serverURL = server.STAGING_SERVER_HOST;
    // app.config.globalProperties.$imageURL = server.STAGING_IMAGE_URL;

} else {

    app.config.globalProperties.$hostURL = server.HOST;
    app.config.globalProperties.$serverURL = server.SERVER_HOST;
    // app.config.globalProperties.$imageURL = server.IMAGE_URL;
    
}

app.mixin(mixins);
app.use(router).mount('#app');