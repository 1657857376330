import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
//retailers
import Retailers from "../components/retailers/template/Retailers";
import AddRetailer from "../components/retailers/template/AddRetailer.vue";
import UpdateRetailer from "../components/retailers/template/UpdateRetailer.vue"
//CMS
import AboutUsSectionOneCMS from "../components/CMS/about-us/SectionOneCMS/template/SectionOneCMS";
import AboutUsSectionTwoCMS from "../components/CMS/about-us/SectionTwoCMS/template/SectionTwoCMS";
import AboutUsSectionThreeCMS from "../components/CMS/about-us/SectionThreeCMS/template/SectionThreeCMS";
import NewsletterBannerCMS from "../components/CMS/newsletter-banner/template/NewsletterBannerCMS";
import ContactUsCMS from "../components/CMS/contact-us/template/ContactUsCMS";
import PrivacyPageCMS from "../components/CMS/privacy-policy/template/PrivacyPageCMS";
import TermsPageCMS from "../components/CMS/terms-and-conditions/template/TermsPageCMS";
import ApplyCareerModalCMS from "../components/CMS/career/ApplyCareerModalCMS/template/ApplyCareerModalCMS";
import ProductMeta from "../components/CMS/header-footer-cms/template/ProductMeta";
import PageTitleCMS from "../components/CMS/header-footer-cms/template/PageTitleCMS";
import ProductsSectionCMS from "../components/CMS/home-page/home-page-products-section-cms/template/ProductsSectionCMS"
import NewArrivalSectionCMS from "../components/CMS/home-page/home-page-new-arrival-section-cms/template/NewArrivalSectionCMS"
import RetailersSectionCMS from "../components/CMS/home-page/home-page-retailers-section-cms/template/RetailersSectionCMS"
import ArticleSectionCMS from "../components/CMS/home-page/home-page-article-section-cms/template/ArticleSectionCMS"
import AdventureSectionCMS from "../components/CMS/adventure-section-cms/template/AdventureSectionCMS.vue"
import HeaderFooterCMS from "../components/CMS/header-footer-cms/template/HeaderFooterCMS.vue"
//Contact
import ContactMessageList from "../components/Contact/template/Contact";

//Hero Section
import Sliders from "../components/CMS/home-page/home-page-hero-section/template/Sliders.vue"
import AddSlider from "../components/CMS/home-page/home-page-hero-section/template/AddSlider.vue"
import UpdateSlider from "../components/CMS/home-page/home-page-hero-section/template/UpdateSlider.vue"


//newsletter
import Newsletter from "../components/newsletter/template/Newsletter.vue"

// Career
import CareerApplicant from "../components/career-applicant/template/CareerApplicant";
import Career from "../components/career/template/Career";
import AddCareer from "../components/career/template/AddCareer";
import EditCareer from "../components/career/template/EditCareer";

// Category
import Category from "../components/category/template/Categories";
import AddCategory from "../components/category/template/AddCategory";
import UpdateCategory from "../components/category/template/UpdateCategory";

//Product
import AddProduct from "../components/product/template/AddProduct";
import UpdateProduct from "../components/product/template/UpdateProduct";
import ProductList from "../components/product/template/Product";

import AddEvent from "../components/events/template/AddEvent.vue";
import UpdateEvent from "../components/events/template/UpdateEvent";
import Events from "../components/events/template/Events.vue";

import BlogPost from "../components/BlogPost/template/BlogPost";
import AddBlogPost from "../components/BlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/BlogPost/template/UpdateBlogPost";

import SocialLinks from "../components/CMS/social-links/template/SocialLinks.vue"
import AgeVerificationCMS from "../components/CMS/age-verification-cms/template/AgeVerificationCMS.vue"

//Component Ui
import HomePageComponentUI from "../components/component-ui/home-page/template/HomePageComponentUI.vue";
import AboutUsComponentUI from "../components/component-ui/about-us/template/AboutUsComponentUI.vue";
import CareerPageComponentUI from "../components/component-ui/career-page/template/CareerPageComponentUI.vue";
import ContactPageComponentUI from "../components/component-ui/contact-page/template/ContactPageComponentUI.vue";
import EventPageComponentUI from "../components/component-ui/event-page/template/EventPageComponentUI.vue";
import FeaturedPageComponentUI from "../components/component-ui/featured-page/template/FeaturedPageComponentUI.vue";
import RetailerPageComponentUI from "../components/component-ui/retailer-page/template/RetailerPageComponentUI.vue";
import ProductPageComponentUI from "../components/component-ui/product-page/template/ProductPageComponentUI.vue";
import BlogPageComponentUI from "../components/component-ui/blog-page/template/BlogPageComponentUI.vue";
import ProductDetailsPageComponentUI from "../components/component-ui/product-details-page/template/ProductDetailsPageComponentUI.vue";
import CareerDetailsPageComponentUI from "../components/component-ui/career-details-page/template/CareerDetailsPageComponentUI.vue";
import HeaderFooterComponentUI from "../components/component-ui/header-footer/template/HeaderFooterComponentUI.vue";
import PrivacyPolicyPageComponentUI from "../components/component-ui/privacy-policy/template/PrivacyPolicyPageComponentUI.vue";
import TermsConditionsPageComponentUI from "../components/component-ui/terms-conditions/template/TermsConditionsPageComponentUI.vue";

const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },

    //CMS
    { path: "/about-us-section-one-cms", name: "AboutUsSectionOneCMS", component: AboutUsSectionOneCMS, beforeEnter: guard },
    { path: "/about-us-section-two-cms", name: "AboutUsSectionTwoCMS", component: AboutUsSectionTwoCMS, beforeEnter: guard },
    { path: "/about-us-section-three-cms", name: "AboutUsSectionThreeCMS", component: AboutUsSectionThreeCMS, beforeEnter: guard },
    { path: "/newsletter-banner-cms", name: "NewsletterBannerCMS", component: NewsletterBannerCMS, beforeEnter: guard },
    { path: "/contact-page-cms", name: "ContactUsCMS", component: ContactUsCMS, beforeEnter: guard },
    { path: "/privacy-policy-page-cms", name: "PrivacyPageCMS", component: PrivacyPageCMS, beforeEnter: guard },
    { path: "/terms-and-condition-page-cms", name: "TermsPageCMS", component: TermsPageCMS, beforeEnter: guard },
    { path: "/apply-career-modal-cms", name: "ApplyCareerModalCMS", component: ApplyCareerModalCMS, beforeEnter: guard },
    { path: "/products-section-cms", name: "ProductsSectionCMS", component: ProductsSectionCMS, beforeEnter: guard },
    { path: "/new-arrival-section-cms", name: "NewArrivalSectionCMS", component: NewArrivalSectionCMS, beforeEnter: guard },
    { path: "/retailers-section-cms", name: "RetailersSectionCMS", component: RetailersSectionCMS, beforeEnter: guard },
    { path: "/article-section-cms", name: "ArticleSectionCMS", component: ArticleSectionCMS, beforeEnter: guard },
    { path: "/adventure-section-cms", name: "AdventureSectionCMS", component: AdventureSectionCMS, beforeEnter: guard },
    { path: "/header-footer-cms", name: "HeaderFooterCMS", component: HeaderFooterCMS, beforeEnter: guard },
    { path: "/age-verification-cms", name: "AgeVerificationCMS", component: AgeVerificationCMS, beforeEnter: guard },
    //Contact
    { path: "/contact", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    // SEO
    { path: "/page-title-cms", name: "PageTitleCMS", component: PageTitleCMS, beforeEnter: guard },
    { path: "/product-meta", name: "ProductMeta", component: ProductMeta, beforeEnter: guard },

    //newsletter
    { path: "/newsletter", name: "Newsletter", component: Newsletter, beforeEnter: guard },

    //Blog
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },
    
    //Hero Section
    { path: "/sliders", name: "Slider", component: Sliders, beforeEnter: guard },
    { path: "/add-slider", name: "AddSlider", component: AddSlider, beforeEnter: guard },
    { path: "/update-slider/:id", name: "UpdateSlider", component: UpdateSlider, beforeEnter: guard },

    /* Career Routes */
    { path: "/career-applicant", name: "CareerApplicant", component: CareerApplicant, beforeEnter: guard },
    { path: "/career", name: "Career", component: Career, beforeEnter: guard },
    { path: "/add-career", name: "AddCareer", component: AddCareer, beforeEnter: guard },
    { path: "/edit-career/:id", name: "EditCareer", component: EditCareer, beforeEnter: guard },

    // Category
    { path: "/category", name: "Category", component: Category, beforeEnter: guard },
    { path: "/add-category", name: "AddCategory", component: AddCategory, beforeEnter: guard },
    { path: "/update-category/:id", name: "UpdateCategory", component: UpdateCategory, beforeEnter: guard },

    // Product
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-list", name: "ProductList", component: ProductList, beforeEnter: guard },

    //event
    { path: "/add-event", name: "AddEvent", component: AddEvent, beforeEnter: guard },
    { path: "/update-event/:id", name: "UpdateEvent", component: UpdateEvent, beforeEnter: guard },
    { path: "/events", name: "Events", component: Events, beforeEnter: guard },

    //retailers
    { path: "/retailers", name: "Retailers", component: Retailers, beforeEnter: guard },
    { path: "/add-retailer", name: "AddRetailer", component: AddRetailer, beforeEnter: guard },
    { path: "/update-retailer/:id", name: "UpdateRetailer", component: UpdateRetailer, beforeEnter: guard },

    //component-ui
    { path: "/home-page-component-ui", name: "HomePageComponentUI", component: HomePageComponentUI, beforeEnter: guard },
    { path: "/about-us-component-ui", name: "AboutUsComponentUI", component: AboutUsComponentUI, beforeEnter: guard },
    { path: "/career-page-component-ui", name: "CareerPageComponentUI", component: CareerPageComponentUI, beforeEnter: guard },
    { path: "/career-details-page-component-ui", name: "CareerDetailsPageComponentUI", component: CareerDetailsPageComponentUI, beforeEnter: guard },
    { path: "/contact-page-component-ui", name: "ContactPageComponentUI", component: ContactPageComponentUI, beforeEnter: guard },
    { path: "/event-page-component-ui", name: "EventPageComponentUI", component: EventPageComponentUI, beforeEnter: guard },
    { path: "/featured-page-component-ui", name: "FeaturedPageComponentUI", component: FeaturedPageComponentUI, beforeEnter: guard },
    { path: "/retailer-page-component-ui", name: "RetailerPageComponentUI", component: RetailerPageComponentUI, beforeEnter: guard },
    { path: "/product-page-component-ui", name: "ProductPageComponentUI", component: ProductPageComponentUI, beforeEnter: guard },
    { path: "/blog-page-component-ui", name: "BlogPageComponentUI", component: BlogPageComponentUI, beforeEnter: guard },
    { path: "/header-footer-component-ui", name: "HeaderFooterComponentUI", component: HeaderFooterComponentUI, beforeEnter: guard },
    { path: "/product-details-page-component-ui", name: "ProductDetailsPageComponentUI", component: ProductDetailsPageComponentUI, beforeEnter: guard },
    { path: "/privacy-policy-component-ui", name: "PrivacyPolicyPageComponentUI", component: PrivacyPolicyPageComponentUI, beforeEnter: guard },
    { path: "/terms-and-conditions-component-ui", name: "TermsConditionsPageComponentUI", component: TermsConditionsPageComponentUI, beforeEnter: guard },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
