<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Products</p>
                  <h4 class="my-1">{{ totalProduct }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <i class="bx bxs-wallet"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Contact Messages</p>
                  <h4 class="my-1">{{ totalContact }}</h4>
                </div>
                <div class="widgets-icons bg-light-warning text-warning ms-auto">
                  <i class="bx bxs-group"></i>
                </div>
              </div>
              <!-- <div id="chart2"></div> -->
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Blogs</p>
                  <h4 class="my-1">{{ totalBlog }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <!-- <i class="bx bx-user-circle"></i> -->
                  <i class='bx bxl-blogger'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Retailers</p>
                  <h4 class="my-1">{{ totalRetailer }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <span class="iconify" data-icon="akar-icons:shipping-box-v2"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Events</p>
                  <h4 class="my-1">{{ totalEvents }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <i class='bx bx-calendar-event'></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Careers</p>
                  <h4 class="my-1">{{ totalCareer }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <span class="iconify" data-icon="bxs:truck"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Categories</p>
                  <h4 class="my-1">{{ totalCategory }}</h4>
                </div>
                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                  <i class='bx bx-category-alt' ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end row-->
      <div class="row">
        <div class="col-xl-12 d-flex">
          <div class="card radius-10 w-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <h5 class="mb-1">Today Product History</h5>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive mt-4">
                    <table class="table table-striped table-bordered" id="example">
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Name</th>
                          <th>Project Name</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in todayProduct" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ data.name }}</td>
                          <td>{{ data.projectName }}</td>
                          <td>{{ $filters.formatDate(data.createdAt) }}</td>
                          <td>
                            <div class="d-flex order-actions">
                              <a href="javascript:void(0);" @click="$router.push('/update-product/' + data.id)"
                                class="edit-button"><i class="bx bxs-show text-white"></i></a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <LinkShortcut />
        </div>
      </div>

      <!-- Contact history -->
      <div class="row">
        <div class="col-xl-12 d-flex">
          <div class="card radius-10 w-100">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <h5 class="mb-1">Recent Contact Messages</h5>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Email</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in contacts" :key="index" >
                                    <td>{{index + 1}}</td>
                                    <td>{{data.email}}</td>
                                    <td>{{data.subject}}</td>
                                    <td>
                                        <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal" @click="updateModalValue(data.message)">
                                            {{ data.message ? data.message.length > 30 ? data.message.slice(0, 30) + '...' : data.message : 'No message found.' }}
                                        </span>
                                    </td>
                                    <td>{{this.$filters.formatDate(data.createdAt)}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Email</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Date</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <LinkShortcut />
        </div>
      </div>
     
    
    </div>
  </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>