import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";


export default {
    components: {
        Loading,
        // LinkShortcut
    },
    data() {
        return {
            dashBoardData: {},
            setURL: this.$serverURL + this.$api.dashBoard.dashboardAPI,
            totalProduct: 0,
            totalBlog: 0,
            totalContact: 0,
            totalRetailer: 0,
            totalCategory: 0,
            totalEvents: 0,
            totalCareer: 0,
            todayProduct: [],
            contacts: []
        }
    },
    async created() {
        document.title = "Mac Pharms - Dashboard";
        await this.getDashBoardData();
        await this.loadJS();
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getDashBoardData() {
            const config = {
                method: "GET",
                url: this.setURL,
                headers: {
                  Authorization: authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.totalProduct = response.data.data.totalProduct;
                    this.totalBlog = response.data.data.totalBlog;
                    this.totalContact = response.data.data.totalContact;
                    this.totalRetailer = response.data.data.totalRetailer;
                    this.totalCategory = response.data.data.totalCategory;
                    this.totalEvents = response.data.data.totalEvents;
                    this.totalCareer = response.data.data.totalCareer;
                    this.todayProduct = response.data.data.todayProduct;
                    this.contacts = response.data.data.contacts.slice(0, 5);
                }
                else {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        }
    },
}