<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Event
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Event</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">

                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputEventTitle" class="form-label">Event Title:</label>
                      <input type="text" class="form-control" id="inputEventTitle" v-model="title"
                        placeholder="Enter event title" />
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row mb-3">
                      <div class="col-md-12">
                        <label class="form-label" for="description">Description:</label>
                        <ckeditor :editor="editor" v-model="description" :config="editorConfig"></ckeditor>
                      </div>
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label for="exampleInputEmail1">Image:
                        <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                          1MB]</strong></label>
                      <input class="form-control mb-2" type="file" name="image" id="image"
                        ref="imageInput" aria-describedby="imageHelp" accept="image/*"
                        @change="uploadFeaturedImage" />
                      <img v-if="previewImage" :src="previewImage" height="90" />
                      <img v-else src="/core/assets/images/400.png" height="80" />
                    </div>
                  </div>
                  
                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="button" @click="addEvent" class="btn btn-primary">
                        Save Event
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-event.js'></script>