<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Header Footer</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Header Footer CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title"></h5>
                    <hr />
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Subscription Title:</label>
                                    <input type="text" v-model="cmsData.subscriptionTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Contact Number:</label>
                                    <input type="text" v-model="cmsData.contactNumber" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Copy Right Text:</label>
                                    <input type="text" v-model="cmsData.copyrightText" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Button Text:</label>
                                    <input type="text" v-model="cmsData.buttonText" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Opening Day:</label>
                                    <input type="text" v-model="cmsData.openingDay" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="border border-3 p-4 rounded mb-3">
                            <div class="row mb-3" v-for="index in counter" :key="index">
                                <div class="col-sm-6">
                                    <label class="form-label">Opening Time:</label>
                                    <div class="d-flex gap-2">
                                        <input type="text" class="form-control" v-model="openingTimeValue[index - 1]" />
                                        <button v-if="counter > 1" @click="deleteOpeningTimeRow(index - 1)" type="button"
                                            class="btn btn-danger">X</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <button type="button" @click="addOpeningTime()" class="btn btn-primary">Add More</button>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="form-label">
                                        Header Logo:
                                    </label>
                                </div>
                                <div class="col-12">
                                    <img style="position: relative; left: 11px" v-if="preview.image" :src="preview.image"
                                        height="70" />
                                    <img style="position: relative; left: 11px" v-else-if="cmsData.headerLogo"
                                        :src="cmsData.headerLogo" height="70" />
                                </div>
                            </div>
                            <div class="mb-3">
                                <input class="form-control mt-3" type="file" ref="image" accept="image/*"
                                    @change="handleUploadImage('headerLogo', 'image', 'image', $event)" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/header-footer-cms.js"></script>