import { authHeader } from './auth';
export default {
    data() {
        return {
            parentCategoriesList: [],
            subCategoriesList: [],
            childCategoryList: [],
            dataList: [],
            pageCMS: []
        }
    },
    methods: {
        getDataList: async function (url) {
            try {
              const config = {
                method: "GET",
                url: url,
                headers: {
                  Authorization: authHeader()
                }
              };
          
              const response = await this.$axios(config);
          
              if (response.status === 200) {
                  if (response.data.data) {
                       this.dataList = response.data.data;
                    } 
                    if (response.data.data.products) {
                        this.dataList = response.data.data.products;
                    }
                    if (response.data.data.posts) {
                        this.dataList = response.data.data.posts;
                    }
                    return this.dataList
              } else {
                throw new Error("Failed! Please try again.");
              }
            } catch (error) {
                console.log(error);
              this.$swal.fire({
                icon: "error",
                text: error.response ? error.response.data.message : "Error occurred while fetching data."
              });
            }
        },
        postData: async function (config) {
            try {
                
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        updateData: async function (config) {
            try {
                let response = await this.$axios(config);
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        deleteData: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateDataToBackend: async function (config) {
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteDataToBackend: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }

            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        postDataToBackend: async function (config) {
            this.$swal.showLoading();
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.status == 201 || response.status == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                else {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        getCmsData: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            return await this.$axios(config);
        },
        createUpdateCMS: async function (url, data) {
            try {
                let response = await this.$axios({
                    method: 'POST',
                    url: url,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                });
                return response.data;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        getAllParentCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.product.getAllCategories,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.parentCategoriesList = response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getParentCategoriesWithSubcategory: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.getParentCategoriesWithSubCategory,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.parentCategoriesList = response.data;
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getSubCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.getSubCategoriesURL,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoriesList = await response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getChildCategory: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.childCategoryURL,
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.childCategoryList = await response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getOrderTotal(data){
            let grandTotal = parseFloat(data.subTotal) - parseFloat(data.discountTotal);
            if(data.shippingCost){
                grandTotal = grandTotal + parseFloat(data.shippingCost)
            }
            if(data.salesTaxAmount){
                grandTotal = grandTotal + parseFloat(data.salesTaxAmount)
            }
            if(data.couponNumber){
                grandTotal = grandTotal - parseFloat(data.couponDiscount)
            }
            if(data.refundAmount && data.refundAmount > 0){
                grandTotal = grandTotal - data.refundAmount
            }
            return grandTotal.toFixed(2);
        },
        showToaster(msg, icon) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: icon,
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },
        createImageCdnForEmailTemplate: async function (config) {
            const response = await this.$axios(config);
            if (response.status == 200 || response.data.data.statusCode == 200) {
                return response.data.data;
            }
        },
        limitText(text, limit) {
            const words = text.trim().split(' ');
            if (words.length > limit) {
                return words.slice(0, limit).join(' ') + '...';
            } else {
                return text;
            }
        },
        cleanText(text) {
            // Remove HTML tags
            const strippedText = text.replace(/(<([^>]+)>)/gi, "");
            // Remove special characters
            const cleanedText = strippedText.replace(/[^\w\s]/gi, "");
            // Trim whitespace
            const trimmedText = cleanedText.trim();
            return trimmedText;
        },
        createUrlSlug(title) {
            let slug = "";
        
            // Convert to lower case
            slug = title.toLowerCase();
        
            // Remove special characters
            slug = slug.replace(/`|~|!|@|#|\||\$|%|\^|&|\*|\(|\)|\+|=|,|\?|>|<|'|"|:|;|_/gi, '');
        
            // Replace spaces with dash symbols
            slug = slug.replace(/ /gi, "-");
        
            // Replace forward slash with dash symbols
            slug = slug.replace(/\//gi, "-");
        
            // Replace dot with dash symbols
            slug = slug.replace(/\./gi, "-");
        
            // Remove consecutive dash symbols 
            slug = slug.replace(/-----/gi, '-');
            slug = slug.replace(/----/gi, '-');
            slug = slug.replace(/---/gi, '-');
            slug = slug.replace(/--/gi, '-');
        
            // Remove the unwanted dash symbols at the beginning and the end of the slug
            slug = '@' + slug + '@';
            slug = slug.replace(/@-|-@|@/gi, '');
        
            return slug;
        },
        loadScripts() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
    }
}