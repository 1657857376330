<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="pageCMSData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.homePageMetaTitle" class="form-control" placeholder="Home page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Title:</label>
                                                <input type="text" v-model="pageCMSData.productPagetitle" class="form-control" placeholder="Product page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.productPageMetaKeyword" class="form-control" placeholder="Product page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.productPageMetaTitle" class="form-control" placeholder="Product page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.productPageMetaDescription" class="form-control" placeholder="Product page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Page Title:</label>
                                                <input type="text" v-model="pageCMSData.featuredPageTitle" class="form-control" placeholder="Featured Page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.featuredPageMetaKeyword" class="form-control" placeholder="Featured Page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.featuredPageMetaTitle" class="form-control" placeholder="Featured Page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Featured Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.featuredPageMetaDescription" class="form-control" placeholder="Featured Page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Title:</label>
                                                <input type="text" v-model="pageCMSData.blogPageTitle" class="form-control" placeholder="Blog page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.blogPageMetaKeyword" class="form-control" placeholder="Blog page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.blogPageMetaTitle" class="form-control" placeholder="Blog page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.blogPageMetaDescription" class="form-control" placeholder="Blog page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Title:</label>
                                                <input type="text" v-model="pageCMSData.retailerPageTitle" class="form-control" placeholder="Retailer Page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.retailerPageMetaKeyword" class="form-control" placeholder="Retailer Page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.retailerPageMetaTitle" class="form-control" placeholder="Retailer Page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Retailer Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.retailerPageMetaDescription" class="form-control" placeholder="Retailer Page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Event Page Title:</label>
                                                <input type="text" v-model="pageCMSData.eventPageTitle" class="form-control" placeholder="Event Page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Event Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.eventPageMetaKeyword" class="form-control" placeholder="Event Page meta Keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Event Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.eventPageMetaTitle" class="form-control" placeholder="Event Page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Event Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.eventPageMetaDescription" class="form-control" placeholder="Event Page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input type="text" v-model="pageCMSData.aboutUsPageTitle" class="form-control" placeholder="About page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.aboutUsPageMetaKeyword" class="form-control" placeholder="About page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.aboutUsPageMetaTitle" class="form-control" placeholder="About page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.aboutUsPageMetaDescription" class="form-control" placeholder="About page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Join Our Team page Title:</label>
                                                <input type="text" v-model="pageCMSData.joinOurTeamPageTitle" class="form-control" placeholder="Join Our Team page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Join Our Team page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.joinOurTeamPageMetaKeyword" class="form-control" placeholder="Join Our Team page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Join Our Team page Meta Title:</label>
                                                <textarea v-model="pageCMSData.joinOurTeamPageMetaTitle" class="form-control" placeholder="Join Our Team page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Join Our Team page Meta Description:</label>
                                                <textarea v-model="pageCMSData.joinOurTeamPageMetaDescription" class="form-control" placeholder="Join Our Team page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Title:</label>
                                                <input type="text" v-model="pageCMSData.careerPageTitle" class="form-control" placeholder="Career page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.careerPageMetaKeyword" class="form-control" placeholder="Career page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.careerPageMetaTitle" class="form-control" placeholder="Career page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.careerPageMetaDescription" class="form-control" placeholder="Career page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Title:</label>
                                                <input type="text" v-model="pageCMSData.contactPageTitle" class="form-control" placeholder="Contact page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaKeyword" class="form-control" placeholder="Contact page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaTitle" class="form-control" placeholder="Contact page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaDescription" class="form-control" placeholder="Contact page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaTitle" class="form-control" placeholder="Privacy page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <!-- <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/> -->
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditon Page Title:</label>
                                                <input type="text" v-model="pageCMSData.termsPageTitle" class="form-control" placeholder="Terms page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditon Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaKeyword" class="form-control" placeholder="Terms page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditon Page Meta Title:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaTitle" class="form-control" placeholder="Terms page meta Title"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms & Conditon Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaDescription" class="form-control" placeholder="Terms page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/page-title-cms.js"></script>