<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Products
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-primary btn-sm m-1" @click="$router.push('/add-product')">
              <i class="bx bxs-plus-square"></i>Add New Product
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Featured Image</th>
                  <th>Category</th>
                  <th>Is Featured</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>
                    <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal2" @click="updateAnswerModalValue(data.description)">
                      {{ data.description ? data.description.length > 50 ? data.description.slice(0, 30) + ' ...' : data.description : 'No category description found.' }}
                    </span>
                  </td>
                  <td>
                    <img v-if="data.featuredImage" :src="data.featuredImage" alt=""  width="90" />
                    <img v-else src="/core/assets/images/400.png" alt=""  width="90" />
                  </td>
                  <td>{{ data.categoryTitle }}</td>
                  <td>{{ data.isFeatured ? 'Featured' : 'Not Featured' }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a href="javascript:void(0);" @click="$router.push('/update-product/' + data.id)"
                        class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                      <a href="javascript:void(0);" @click="deleteProduct(data.id)" class="ms-3 delete-button"><i
                          class="bx bxs-trash text-white"></i></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Featured Image</th>
                  <th>Category</th>
                  <th>Is Featured</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="showModal2" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Description</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="description"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script src='../js/product.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>